/*PAGES*/

#pages{
    display: none;
}

#page-container {
    position: relative;
    min-height: 100vh;
}

.page{
    padding: 1rem;

}

.page-title {
    color: white;
    text-align: center;
}

/* UPLOAD page */

#initialize {
    text-align: center;
    padding: 1rem;
    /*display: none;*/
}

#upload-form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

#upload-btn {
    margin-top: 1rem;
}

#get-sample {
    font-weight: bold;
    color: #217484;
    cursor: pointer;
}


/*PAGE TRAINING*/

#page-train{
    display: none;
    flex-direction: column;
    align-items: center;
}

.image-stack {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    position: relative;
}

.image-bottom {
    grid-column: 1;
    grid-row: 1;
}

.image-top {
    grid-column: 1;
    grid-row: 1;
    z-index: 1;
}

.note-img{
    display: none;
    margin: auto;
    height:100px;
}

.frequency-img, .frequency-img-base {
    width: 300px;
    height: 100px;
    margin-left: auto;
    margin-right: auto;
}
.frequency-img{
    display: none;
}
#note-caption{
    margin-top: 3rem;
}

#frequency-caption, #time-caption {
    margin-top: 1rem;
}

#note-train {
    margin-top: 2rem;
}

#visualizer-train {
    padding: 1em 4em 1em 4em;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
}

#canvas-time, #canvas-train, #canvas-master {
    margin-left: auto;
    margin-right: auto;
    display: block;
    background-color: whitesmoke;
}

#controls-train {
    display: flex;
}

#controls-train button {
    margin: 1em;
}


/* ------- LISTEN page -------- */

#page-listen {
    text-align: center;
}

.sheet {
    -webkit-box-shadow: 1px 1px 8px 0px rgba(17, 45, 50, 0.86);
    -moz-box-shadow: 1px 1px 8px 0px rgba(17, 45, 50, 0.86);
    box-shadow: 1px 1px 8px 0px rgba(17, 45, 50, 0.86);
}

.sheet-music-display {
    margin: 0 8rem 0 8rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-bottom: 2rem;
}

#score {
    background-color: whitesmoke;
    width: 720px;
}

#loading-indicator {
    visibility: hidden;
}

#audio-player {
    padding: 1rem;
    position: fixed;
    top: 35%;
    left:15%;
}

#listen-info{
    position: fixed;
    top: 45%;
    left:16%;
    color: white;
}

/* ------- LEARN page -------- */

.up, .down, .correct {
    color: whitesmoke;
}

#page-learn {
    margin: 0 8rem 0 8rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

#visualizer {
    padding: 1rem;
    position: fixed;
    top: 26%;
    left: 12%;
}

.control-buttons {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 63%;
    left: 17%;
}

.feedback {
    position: fixed;
    top: 62%;
    left: 27%;
}

#note {
    padding: 0.5rem;
    text-align: center;
    /*visibility: hidden;*/
}

#notes {
    position: fixed;
    top: 44%;
    left: 15%;
    color: whitesmoke;
}

.note-display {
    display: flex;
    align-items: center;
}

#crt-song, #init-song {
    display: none;
}


#start, #stop {
    margin: 0.5em;
}

.centered {
    display: flex;
    align-items: center;
}

#congrats {
    visibility: hidden;
    text-align: center;
    display: block;
    color: white;
}

/* ------- MASTER page -------- */

#master-controls {
    text-align: center;
    padding: 1rem;
    position: fixed;
    top: 12rem;
    left: 12rem;
}

#canvas-master{
    position: fixed;
    top: 18rem;
    left: 10rem;
}

.master-buttons{
    position: fixed;
    top: 28rem;
    left: 10rem;
}

#start-master, #stop-master{
    margin: 0.5em;
}

.feedback-master{
    position: fixed;
    top: 26rem;
    left: 25rem;
}

#master-progress{
    position: fixed;
    top: 38rem;
    left: 5rem;
    width: 30%;
}

input[type=range]::-webkit-slider-thumb {
    background: #0c5460;
}

input[type=range]::-moz-range-thumb {
    background: #0c5460;
}

input[type=range]::-ms-thumb {
    background: #0c5460;
}

.custom-range:focus::-moz-range-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgb(241, 238, 238);
    background: #0c5460;
}

.custom-range:focus::-webkit-slider-thumb {
    box-shadow: 0 0 0 1px #fff, 0 0 0 .2rem rgb(241, 238, 238);
    background: #0c5460;
}

.custom-range:active::-moz-range-thumb {
    background: #0c5460;
}

.custom-range:active::-webkit-slider-thumb {
    background: #0c5460;
}
